var __assign = Object.assign;
/**
 * @license
 * Copyright 2021 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const _str = (strings, ...values) => ({
  strTag: true,
  strings,
  values
});
const str = _str;
const isStrTagged = (val) => typeof val !== "string" && "strTag" in val;
const joinStringsAndValues = (strings, values, valueOrder) => {
  let concat = strings[0];
  for (let i = 1; i < strings.length; i++) {
    concat += values[valueOrder ? valueOrder[i - 1] : i - 1];
    concat += strings[i];
  }
  return concat;
};
/**
 * @license
 * Copyright 2021 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const defaultMsg = (template) => isStrTagged(template) ? joinStringsAndValues(template.strings, template.values) : template;
/**
 * @license
 * Copyright 2021 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const LOCALE_STATUS_EVENT = "lit-localize-status";
/**
 * @license
 * Copyright 2020 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
class Deferred {
  constructor() {
    this.settled = false;
    this.promise = new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }
  resolve(value) {
    this.settled = true;
    this._resolve(value);
  }
  reject(error) {
    this.settled = true;
    this._reject(error);
  }
}
/**
 * @license
 * Copyright 2014 Travis Webb
 * SPDX-License-Identifier: MIT
 */
const hl = [];
for (let i = 0; i < 256; i++) {
  hl[i] = (i >> 4 & 15).toString(16) + (i & 15).toString(16);
}
function fnv1a64(str2) {
  let t0 = 0, v0 = 8997, t1 = 0, v1 = 33826, t2 = 0, v2 = 40164, t3 = 0, v3 = 52210;
  for (let i = 0; i < str2.length; i++) {
    v0 ^= str2.charCodeAt(i);
    t0 = v0 * 435;
    t1 = v1 * 435;
    t2 = v2 * 435;
    t3 = v3 * 435;
    t2 += v0 << 8;
    t3 += v1 << 8;
    t1 += t0 >>> 16;
    v0 = t0 & 65535;
    t2 += t1 >>> 16;
    v1 = t1 & 65535;
    v3 = t3 + (t2 >>> 16) & 65535;
    v2 = t2 & 65535;
  }
  return hl[v3 >> 8] + hl[v3 & 255] + hl[v2 >> 8] + hl[v2 & 255] + hl[v1 >> 8] + hl[v1 & 255] + hl[v0 >> 8] + hl[v0 & 255];
}
/**
 * @license
 * Copyright 2020 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const HASH_DELIMITER = "";
const HTML_PREFIX = "h";
const STRING_PREFIX = "s";
function generateMsgId(strings, isHtmlTagged) {
  return (isHtmlTagged ? HTML_PREFIX : STRING_PREFIX) + fnv1a64(typeof strings === "string" ? strings : strings.join(HASH_DELIMITER));
}
/**
 * @license
 * Copyright 2021 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const expressionOrders = new WeakMap();
const hashCache = new Map();
function runtimeMsg(templates2, template, options) {
  var _a;
  if (templates2) {
    const id = (_a = options === null || options === void 0 ? void 0 : options.id) !== null && _a !== void 0 ? _a : generateId(template);
    const localized = templates2[id];
    if (localized) {
      if (typeof localized === "string") {
        return localized;
      } else if ("strTag" in localized) {
        return joinStringsAndValues(localized.strings, template.values, localized.values);
      } else {
        let order = expressionOrders.get(localized);
        if (order === void 0) {
          order = localized.values;
          expressionOrders.set(localized, order);
        }
        return __assign(__assign({}, localized), {
          values: order.map((i) => template.values[i])
        });
      }
    }
  }
  return defaultMsg(template);
}
function generateId(template) {
  const strings = typeof template === "string" ? template : template.strings;
  let id = hashCache.get(strings);
  if (id === void 0) {
    id = generateMsgId(strings, typeof template !== "string" && !("strTag" in template));
    hashCache.set(strings, id);
  }
  return id;
}
/**
 * @license
 * Copyright 2021 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
function dispatchStatusEvent(detail) {
  window.dispatchEvent(new CustomEvent(LOCALE_STATUS_EVENT, {detail}));
}
let activeLocale = "";
let loadingLocale;
let sourceLocale;
let validLocales;
let loadLocale;
let templates;
let loading = new Deferred();
loading.resolve();
let requestId = 0;
const configureLocalization = (config) => {
  _installMsgImplementation((template, options) => runtimeMsg(templates, template, options));
  activeLocale = sourceLocale = config.sourceLocale;
  validLocales = new Set(config.targetLocales);
  validLocales.add(config.sourceLocale);
  loadLocale = config.loadLocale;
  return {getLocale, setLocale};
};
const getLocale = () => {
  return activeLocale;
};
const setLocale = (newLocale) => {
  if (newLocale === (loadingLocale !== null && loadingLocale !== void 0 ? loadingLocale : activeLocale)) {
    return loading.promise;
  }
  if (!validLocales || !loadLocale) {
    throw new Error("Internal error");
  }
  if (!validLocales.has(newLocale)) {
    throw new Error("Invalid locale code");
  }
  requestId++;
  const thisRequestId = requestId;
  loadingLocale = newLocale;
  if (loading.settled) {
    loading = new Deferred();
  }
  dispatchStatusEvent({status: "loading", loadingLocale: newLocale});
  const localePromise = newLocale === sourceLocale ? Promise.resolve({templates: void 0}) : loadLocale(newLocale);
  localePromise.then((mod) => {
    if (requestId === thisRequestId) {
      activeLocale = newLocale;
      loadingLocale = void 0;
      templates = mod.templates;
      dispatchStatusEvent({status: "ready", readyLocale: newLocale});
      loading.resolve();
    }
  }, (err) => {
    if (requestId === thisRequestId) {
      dispatchStatusEvent({
        status: "error",
        errorLocale: newLocale,
        errorMessage: err.toString()
      });
      loading.reject(err);
    }
  });
  return loading.promise;
};
/**
 * @license
 * Copyright 2020 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
let msg = defaultMsg;
let installed = false;
function _installMsgImplementation(impl) {
  if (installed) {
    throw new Error("lit-localize can only be configured once");
  }
  msg = impl;
  installed = true;
}
export {LOCALE_STATUS_EVENT as L, configureLocalization as c, msg as m, str as s};
