function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {value: t, enumerable: true, configurable: true, writable: true}) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return typeof i == "symbol" ? i : i + "";
}
function _toPrimitive(t, r) {
  if (typeof t != "object" || !t)
    return t;
  var e = t[Symbol.toPrimitive];
  if (e !== void 0) {
    var i = e.call(t, r || "default");
    if (typeof i != "object")
      return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (r === "string" ? String : Number)(t);
}
export class DonationBannerHandler {
  async pageNavigationOccurred(patronShouldSeeBanner = true) {
    if (window.location.pathname.startsWith("/donate") || !patronShouldSeeBanner) {
      await this.removeDonationBanner();
      return;
    }
    await this.addDonationBanner();
  }
  constructor(options) {
    _defineProperty(this, "ownerId", "donationBannerHandler");
    _defineProperty(this, "baseUrl", void 0);
    this.baseUrl = options.baseUrl;
  }
  async removeDonationBanner() {
    var _a;
    (_a = this.bannerContainer) == null ? void 0 : _a.remove();
  }
  async addDonationBanner() {
    if (this.bannerContainer)
      return;
    const containerDiv = document.createElement("div");
    containerDiv.dataset.owner = this.ownerId;
    const iframeContainer = document.createElement("div");
    iframeContainer.id = "donato";
    const scriptTag = document.createElement("script");
    scriptTag.type = "text/javascript";
    scriptTag.src = `https://${this.baseUrl}/includes/donate.js`;
    containerDiv.appendChild(iframeContainer);
    containerDiv.appendChild(scriptTag);
    document.body.prepend(containerDiv);
  }
  get bannerContainer() {
    return document.body.querySelector(`[data-owner=${this.ownerId}]`);
  }
}
