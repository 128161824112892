var __assign = Object.assign;
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const o = ({finisher: e, descriptor: t}) => (o2, n) => {
  var r;
  if (n === void 0) {
    const n2 = (r = o2.originalKey) !== null && r !== void 0 ? r : o2.key, i2 = t != null ? {kind: "method", placement: "prototype", key: n2, descriptor: t(o2.key)} : __assign(__assign({}, o2), {key: n2});
    return e != null && (i2.finisher = function(t2) {
      e(t2, n2);
    }), i2;
  }
  {
    const r2 = o2.constructor;
    t !== void 0 && Object.defineProperty(o2, n, t(n)), e == null || e(r2, n);
  }
};
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
function i(i2, n) {
  return o({descriptor: (o2) => {
    const t = {get() {
      var o3, n2;
      return (n2 = (o3 = this.renderRoot) === null || o3 === void 0 ? void 0 : o3.querySelector(i2)) !== null && n2 !== void 0 ? n2 : null;
    }, enumerable: true, configurable: true};
    if (n) {
      const n2 = typeof o2 == "symbol" ? Symbol() : "__" + o2;
      t.get = function() {
        var o3, t2;
        return this[n2] === void 0 && (this[n2] = (t2 = (o3 = this.renderRoot) === null || o3 === void 0 ? void 0 : o3.querySelector(i2)) !== null && t2 !== void 0 ? t2 : null), this[n2];
      };
    }
    return t;
  }});
}
export {i, o};
