import {msg, str} from "../../__snowpack__/pkg/@lit/localize.js";
export function formatMediaCount(number, format = "long") {
  const divisor = magnitude(number);
  const rounded = round(number, divisor);
  return labelize(rounded, divisor, format);
}
function magnitude(number) {
  let divisor = 1;
  if (number >= 1e9) {
    divisor = 1e9;
  } else if (number >= 1e6) {
    divisor = 1e6;
  } else if (number >= 1e3) {
    divisor = 1e3;
  }
  return divisor;
}
function round(number = 0, divisor) {
  const result = number / divisor;
  const roundToOne = result < 10;
  let rounded = 0;
  if (roundToOne) {
    rounded = Math.round((result + Number.EPSILON) * 10) / 10;
  } else {
    rounded = Math.round(result);
  }
  return rounded;
}
function labelize(rounded, divisor, format) {
  switch (divisor) {
    case 1e9:
      if (format === "short") {
        return msg(str`${rounded}B`);
      } else {
        return msg(str`${rounded} billion`);
      }
    case 1e6:
      if (format === "short") {
        return msg(str`${rounded}M`);
      } else {
        return msg(str`${rounded} million`);
      }
    case 1e3:
      if (format === "short") {
        return msg(str`${rounded}K`);
      } else {
        return msg(str`${rounded} thousand`);
      }
    default:
      return `${rounded}`;
  }
}
