import {a as __awaiter} from "./tslib.es6-46d5edc4.js";
class PromisedSingleton {
  constructor(options) {
    this.generator = options.generator;
  }
  get() {
    return __awaiter(this, void 0, void 0, function* () {
      if (this.cachedResponse) {
        return this.cachedResponse;
      }
      if (this.previousPromise) {
        this.previousPromise = this.previousPromise.then((response) => {
          return response;
        });
        return this.previousPromise;
      }
      this.previousPromise = this.generateSingletonAndCache();
      return this.previousPromise;
    });
  }
  reset() {
    this.cachedResponse = void 0;
    this.previousPromise = void 0;
  }
  generateSingletonAndCache() {
    return __awaiter(this, void 0, void 0, function* () {
      const result = yield this.generator();
      this.cachedResponse = result;
      return result;
    });
  }
}
export {PromisedSingleton as P};
