import {Directive, directive} from "../../__snowpack__/pkg/lit/directive.js";
const resolved = new WeakSet();
export class LazyLoadTemplate extends Directive {
  constructor(partInfo) {
    super(partInfo);
  }
  update(part, [importPromise, value]) {
    if (!resolved.has(part)) {
      importPromise();
      resolved.add(part);
    }
    return this.render(importPromise, value);
  }
  render(importPromise, value) {
    return value();
  }
}
export const lazyLoadTemplate = directive(LazyLoadTemplate);
