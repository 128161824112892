function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {value: t, enumerable: true, configurable: true, writable: true}) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return typeof i == "symbol" ? i : i + "";
}
function _toPrimitive(t, r) {
  if (typeof t != "object" || !t)
    return t;
  var e = t[Symbol.toPrimitive];
  if (e !== void 0) {
    var i = e.call(t, r || "default");
    if (typeof i != "object")
      return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (r === "string" ? String : Number)(t);
}
export class DonateBannerService {
  async getBannerConfig(options) {
    const searchParams = new URLSearchParams();
    if (options == null ? void 0 : options.ymd)
      searchParams.append("ymd", options.ymd);
    if (options == null ? void 0 : options.variant)
      searchParams.append("variant", options.variant);
    if (options == null ? void 0 : options.platform)
      searchParams.append("platform", options.platform);
    if (options == null ? void 0 : options.recacheDonationBanner)
      searchParams.append("recacheDonationBanner", options.recacheDonationBanner);
    let url = this.endpoint;
    const searchString = searchParams.toString();
    if (searchString.length > 0) {
      url = `${url}?${searchString}`;
    }
    const decoded = await this.fetchHandler.fetchIAApiResponse(url, {
      includeCredentials: true
    });
    return decoded.value;
  }
  constructor(options) {
    _defineProperty(this, "fetchHandler", void 0);
    _defineProperty(this, "endpoint", "/services/donations/banner.php");
    this.fetchHandler = options.fetchHandler;
  }
}
