import {promisedSleep} from "./promised-sleep.js";
import {AnalyticsHandler} from "../core/app-services/analytics/analytics-handler.js";
class ImportRetryError extends Error {
  constructor(message) {
    super(message);
    this.name = "ImportRetryError";
  }
}
const analyticsHandler = new AnalyticsHandler({
  enableAnalytics: true
});
const defaultRetryCount = 2;
export async function importRetry(importFn, retries = defaultRetryCount, interval = 1e3) {
  try {
    return await importFn();
  } catch (error) {
    let errorMessage = "Error loading import";
    if (typeof error === "string") {
      errorMessage = error;
    } else if (error instanceof Error) {
      errorMessage = error.message;
    }
    if (retries) {
      await promisedSleep(interval);
      const retryNumber = defaultRetryCount - retries + 1;
      analyticsHandler.sendEvent({
        category: "offshootImportRetry",
        action: "retryingImport",
        label: `retryNumber: ${retryNumber} / ${defaultRetryCount}, error: ${errorMessage}`
      });
      return importRetry(importFn, retries - 1, interval);
    } else {
      analyticsHandler.sendEvent({
        category: "offshootImportRetry",
        action: "importFailed",
        label: errorMessage
      });
      throw new ImportRetryError(errorMessage);
    }
  }
}
