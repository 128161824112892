function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {value: t, enumerable: true, configurable: true, writable: true}) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return typeof i == "symbol" ? i : i + "";
}
function _toPrimitive(t, r) {
  if (typeof t != "object" || !t)
    return t;
  var e = t[Symbol.toPrimitive];
  if (e !== void 0) {
    var i = e.call(t, r || "default");
    if (typeof i != "object")
      return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (r === "string" ? String : Number)(t);
}
import {AnalyticsHelpers, AnalyticsManager} from "../../../../__snowpack__/pkg/@internetarchive/analytics-manager.js";
export class AnalyticsHandler {
  constructor(options) {
    _defineProperty(this, "analyticsBackend", void 0);
    _defineProperty(this, "analyticsHelpers", void 0);
    if (!options.enableAnalytics)
      return;
    this.analyticsBackend = new AnalyticsManager();
    this.analyticsHelpers = new AnalyticsHelpers(this.analyticsBackend);
  }
  sendPing(values) {
    var _a;
    (_a = this.analyticsBackend) == null ? void 0 : _a.sendPing(values);
  }
  sendEvent(options) {
    var _a;
    (_a = this.analyticsBackend) == null ? void 0 : _a.sendEvent(options);
  }
  send_event(category, action, label, additionalEventParams) {
    this.sendEvent({
      category,
      action,
      label,
      eventConfiguration: additionalEventParams
    });
  }
  sendEventNoSampling(options) {
    var _a;
    (_a = this.analyticsBackend) == null ? void 0 : _a.sendEventNoSampling(options);
  }
  trackIaxParameter(location) {
    var _a;
    (_a = this.analyticsHelpers) == null ? void 0 : _a.trackIaxParameter(location);
  }
  trackPageView(options) {
    var _a;
    (_a = this.analyticsHelpers) == null ? void 0 : _a.trackPageView(options);
  }
}
