var __assign = Object.assign;
import * as __SNOWPACK_ENV__ from "../__snowpack__/env.js";
function _decorate(e, r, t, i) {
  var o = _getDecoratorsApi();
  if (i)
    for (var n = 0; n < i.length; n++)
      o = i[n](o);
  var s = r(function(e2) {
    o.initializeInstanceElements(e2, a.elements);
  }, t), a = o.decorateClass(_coalesceClassElements(s.d.map(_createElementDescriptor)), e);
  return o.initializeClassElements(s.F, a.elements), o.runClassFinishers(s.F, a.finishers);
}
function _getDecoratorsApi() {
  _getDecoratorsApi = function() {
    return e;
  };
  var e = {elementsDefinitionOrder: [["method"], ["field"]], initializeInstanceElements: function(e2, r) {
    ["method", "field"].forEach(function(t) {
      r.forEach(function(r2) {
        r2.kind === t && r2.placement === "own" && this.defineClassElement(e2, r2);
      }, this);
    }, this);
  }, initializeClassElements: function(e2, r) {
    var t = e2.prototype;
    ["method", "field"].forEach(function(i) {
      r.forEach(function(r2) {
        var o = r2.placement;
        if (r2.kind === i && (o === "static" || o === "prototype")) {
          var n = o === "static" ? e2 : t;
          this.defineClassElement(n, r2);
        }
      }, this);
    }, this);
  }, defineClassElement: function(e2, r) {
    var t = r.descriptor;
    if (r.kind === "field") {
      var i = r.initializer;
      t = {enumerable: t.enumerable, writable: t.writable, configurable: t.configurable, value: i === void 0 ? void 0 : i.call(e2)};
    }
    Object.defineProperty(e2, r.key, t);
  }, decorateClass: function(e2, r) {
    var t = [], i = [], o = {static: [], prototype: [], own: []};
    if (e2.forEach(function(e3) {
      this.addElementPlacement(e3, o);
    }, this), e2.forEach(function(e3) {
      if (!_hasDecorators(e3))
        return t.push(e3);
      var r2 = this.decorateElement(e3, o);
      t.push(r2.element), t.push.apply(t, r2.extras), i.push.apply(i, r2.finishers);
    }, this), !r)
      return {elements: t, finishers: i};
    var n = this.decorateConstructor(t, r);
    return i.push.apply(i, n.finishers), n.finishers = i, n;
  }, addElementPlacement: function(e2, r, t) {
    var i = r[e2.placement];
    if (!t && i.indexOf(e2.key) !== -1)
      throw new TypeError("Duplicated element (" + e2.key + ")");
    i.push(e2.key);
  }, decorateElement: function(e2, r) {
    for (var t = [], i = [], o = e2.decorators, n = o.length - 1; n >= 0; n--) {
      var s = r[e2.placement];
      s.splice(s.indexOf(e2.key), 1);
      var a = this.fromElementDescriptor(e2), l = this.toElementFinisherExtras((0, o[n])(a) || a);
      e2 = l.element, this.addElementPlacement(e2, r), l.finisher && i.push(l.finisher);
      var c = l.extras;
      if (c) {
        for (var p = 0; p < c.length; p++)
          this.addElementPlacement(c[p], r);
        t.push.apply(t, c);
      }
    }
    return {element: e2, finishers: i, extras: t};
  }, decorateConstructor: function(e2, r) {
    for (var t = [], i = r.length - 1; i >= 0; i--) {
      var o = this.fromClassDescriptor(e2), n = this.toClassDescriptor((0, r[i])(o) || o);
      if (n.finisher !== void 0 && t.push(n.finisher), n.elements !== void 0) {
        e2 = n.elements;
        for (var s = 0; s < e2.length - 1; s++)
          for (var a = s + 1; a < e2.length; a++)
            if (e2[s].key === e2[a].key && e2[s].placement === e2[a].placement)
              throw new TypeError("Duplicated element (" + e2[s].key + ")");
      }
    }
    return {elements: e2, finishers: t};
  }, fromElementDescriptor: function(e2) {
    var r = {kind: e2.kind, key: e2.key, placement: e2.placement, descriptor: e2.descriptor};
    return Object.defineProperty(r, Symbol.toStringTag, {value: "Descriptor", configurable: true}), e2.kind === "field" && (r.initializer = e2.initializer), r;
  }, toElementDescriptors: function(e2) {
    if (e2 !== void 0)
      return _toArray(e2).map(function(e3) {
        var r = this.toElementDescriptor(e3);
        return this.disallowProperty(e3, "finisher", "An element descriptor"), this.disallowProperty(e3, "extras", "An element descriptor"), r;
      }, this);
  }, toElementDescriptor: function(e2) {
    var r = e2.kind + "";
    if (r !== "method" && r !== "field")
      throw new TypeError(`An element descriptor's .kind property must be either "method" or "field", but a decorator created an element descriptor with .kind "` + r + '"');
    var t = _toPropertyKey(e2.key), i = e2.placement + "";
    if (i !== "static" && i !== "prototype" && i !== "own")
      throw new TypeError(`An element descriptor's .placement property must be one of "static", "prototype" or "own", but a decorator created an element descriptor with .placement "` + i + '"');
    var o = e2.descriptor;
    this.disallowProperty(e2, "elements", "An element descriptor");
    var n = {kind: r, key: t, placement: i, descriptor: Object.assign({}, o)};
    return r !== "field" ? this.disallowProperty(e2, "initializer", "A method descriptor") : (this.disallowProperty(o, "get", "The property descriptor of a field descriptor"), this.disallowProperty(o, "set", "The property descriptor of a field descriptor"), this.disallowProperty(o, "value", "The property descriptor of a field descriptor"), n.initializer = e2.initializer), n;
  }, toElementFinisherExtras: function(e2) {
    return {element: this.toElementDescriptor(e2), finisher: _optionalCallableProperty(e2, "finisher"), extras: this.toElementDescriptors(e2.extras)};
  }, fromClassDescriptor: function(e2) {
    var r = {kind: "class", elements: e2.map(this.fromElementDescriptor, this)};
    return Object.defineProperty(r, Symbol.toStringTag, {value: "Descriptor", configurable: true}), r;
  }, toClassDescriptor: function(e2) {
    var r = e2.kind + "";
    if (r !== "class")
      throw new TypeError(`A class descriptor's .kind property must be "class", but a decorator created a class descriptor with .kind "` + r + '"');
    this.disallowProperty(e2, "key", "A class descriptor"), this.disallowProperty(e2, "placement", "A class descriptor"), this.disallowProperty(e2, "descriptor", "A class descriptor"), this.disallowProperty(e2, "initializer", "A class descriptor"), this.disallowProperty(e2, "extras", "A class descriptor");
    var t = _optionalCallableProperty(e2, "finisher");
    return {elements: this.toElementDescriptors(e2.elements), finisher: t};
  }, runClassFinishers: function(e2, r) {
    for (var t = 0; t < r.length; t++) {
      var i = (0, r[t])(e2);
      if (i !== void 0) {
        if (typeof i != "function")
          throw new TypeError("Finishers must return a constructor.");
        e2 = i;
      }
    }
    return e2;
  }, disallowProperty: function(e2, r, t) {
    if (e2[r] !== void 0)
      throw new TypeError(t + " can't have a ." + r + " property.");
  }};
  return e;
}
function _createElementDescriptor(e) {
  var r, t = _toPropertyKey(e.key);
  e.kind === "method" ? r = {value: e.value, writable: true, configurable: true, enumerable: false} : e.kind === "get" ? r = {get: e.value, configurable: true, enumerable: false} : e.kind === "set" ? r = {set: e.value, configurable: true, enumerable: false} : e.kind === "field" && (r = {configurable: true, writable: true, enumerable: true});
  var i = {kind: e.kind === "field" ? "field" : "method", key: t, placement: e.static ? "static" : e.kind === "field" ? "own" : "prototype", descriptor: r};
  return e.decorators && (i.decorators = e.decorators), e.kind === "field" && (i.initializer = e.value), i;
}
function _coalesceGetterSetter(e, r) {
  e.descriptor.get !== void 0 ? r.descriptor.get = e.descriptor.get : r.descriptor.set = e.descriptor.set;
}
function _coalesceClassElements(e) {
  for (var r = [], isSameElement = function(e2) {
    return e2.kind === "method" && e2.key === o.key && e2.placement === o.placement;
  }, t = 0; t < e.length; t++) {
    var i, o = e[t];
    if (o.kind === "method" && (i = r.find(isSameElement))) {
      if (_isDataDescriptor(o.descriptor) || _isDataDescriptor(i.descriptor)) {
        if (_hasDecorators(o) || _hasDecorators(i))
          throw new ReferenceError("Duplicated methods (" + o.key + ") can't be decorated.");
        i.descriptor = o.descriptor;
      } else {
        if (_hasDecorators(o)) {
          if (_hasDecorators(i))
            throw new ReferenceError("Decorators can't be placed on different accessors with for the same property (" + o.key + ").");
          i.decorators = o.decorators;
        }
        _coalesceGetterSetter(o, i);
      }
    } else
      r.push(o);
  }
  return r;
}
function _hasDecorators(e) {
  return e.decorators && e.decorators.length;
}
function _isDataDescriptor(e) {
  return e !== void 0 && !(e.value === void 0 && e.writable === void 0);
}
function _optionalCallableProperty(e, r) {
  var t = e[r];
  if (t !== void 0 && typeof t != "function")
    throw new TypeError("Expected '" + r + "' to be a function");
  return t;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return typeof i == "symbol" ? i : i + "";
}
function _toPrimitive(t, r) {
  if (typeof t != "object" || !t)
    return t;
  var e = t[Symbol.toPrimitive];
  if (e !== void 0) {
    var i = e.call(t, r || "default");
    if (typeof i != "object")
      return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (r === "string" ? String : Number)(t);
}
function _toArray(r) {
  return _arrayWithHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableRest();
}
function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if (typeof r == "string")
      return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return t === "Object" && r.constructor && (t = r.constructor.name), t === "Map" || t === "Set" ? Array.from(r) : t === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _arrayLikeToArray(r, a) {
  (a == null || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++)
    n[e] = r[e];
  return n;
}
function _iterableToArray(r) {
  if (typeof Symbol != "undefined" && r[Symbol.iterator] != null || r["@@iterator"] != null)
    return Array.from(r);
}
function _arrayWithHoles(r) {
  if (Array.isArray(r))
    return r;
}
import {LitElement, html, css, nothing} from "../__snowpack__/pkg/lit.js";
import {property, query, state, customElement} from "../__snowpack__/pkg/lit/decorators.js";
import {msg} from "../__snowpack__/pkg/@lit/localize.js";
import "../__snowpack__/pkg/@internetarchive/ia-topnav.js";
import "../__snowpack__/pkg/@internetarchive/router-slot.js";
import "./widgets/activity-indicators/horizontal-activity-indicator.js";
import {AppFooterMode} from "./app-footer-mode.js";
import {lazyLoadTemplate} from "./core/lazy-load-template.js";
import {PromisedSingleton} from "../__snowpack__/pkg/@internetarchive/promised-singleton.js";
import {DonationBannerHandler} from "./core/donation-banner/donation-banner-handler.js";
import {DonateBannerService} from "./core/donation-banner/donation-banner-service.js";
import {RouteBuilder} from "./core/route-builder/route-builder.js";
import {formatMediaCount} from "./utils/format-media-count.js";
import {importRetry} from "./utils/import-retry.js";
export let AppRoot = _decorate([customElement("app-root")], function(_initialize, _LitElement) {
  class AppRoot2 extends _LitElement {
    constructor(...args) {
      super(...args);
      _initialize(this);
    }
  }
  return {
    F: AppRoot2,
    d: [{
      kind: "field",
      decorators: [property({
        type: Object
      })],
      key: "appServices",
      value() {
        return new PromisedSingleton({
          generator: async () => {
            const module = await importRetry(() => import(
              /* webpackChunkName: 'appServices' */
              "./core/app-services/app-services.js"
            ));
            return module.AppServices.generateAppServices({
              appRoot: this,
              appConfig: this.appConfig,
              modalManager: new PromisedSingleton({
                generator: async () => {
                  return this.modalManager;
                }
              })
            });
          }
        });
      }
    }, {
      kind: "field",
      decorators: [property({
        type: Object
      })],
      key: "appConfig",
      value() {
        return new PromisedSingleton({
          generator: async () => {
            const metaEnv = __SNOWPACK_ENV__;
            const urlParams = new URLSearchParams(window.location.search);
            const prerenderParam = urlParams.get("prerender");
            const isPrerendering = prerenderParam === "1";
            const isTesting = metaEnv.SNOWPACK_PUBLIC_TESTING === "true";
            const suppressFacets = metaEnv.SNOWPACK_PUBLIC_SUPPRESS_FACETS === "true";
            const tracesSampleRate = parseFloat(metaEnv.SNOWPACK_PUBLIC_SENTRY_TRACES_SAMPLE_RATE);
            const sentryErrorSampleRate = parseFloat(metaEnv.SNOWPACK_PUBLIC_SENTRY_ERROR_SAMPLE_RATE);
            return {
              environment: metaEnv.SNOWPACK_PUBLIC_ENVIRONMENT,
              documentReferrer: document.referrer,
              basePath: metaEnv.SNOWPACK_PUBLIC_BASE_PATH,
              version: metaEnv.SNOWPACK_PUBLIC_VERSION,
              archiveNavUrl: metaEnv.SNOWPACK_PUBLIC_ARCHIVE_NAV_URL,
              archiveApiUrl: metaEnv.SNOWPACK_PUBLIC_ARCHIVE_API_URL,
              waybackUrl: metaEnv.SNOWPACK_PUBLIC_WAYBACK_URL,
              waybackSearchUrl: metaEnv.SNOWPACK_PUBLIC_WAYBACK_SEARCH_URL,
              tvSearchUrl: metaEnv.SNOWPACK_PUBLIC_TV_SEARCH_URL,
              radioSearchUrl: metaEnv.SNOWPACK_PUBLIC_RADIO_SEARCH_URL,
              archiveItCollectionSearchUrl: metaEnv.SNOWPACK_PUBLIC_ARCHIVE_IT_COLLECTION_URL,
              archiveItPartnerSearchUrl: metaEnv.SNOWPACK_PUBLIC_ARCHIVE_IT_PARTNER_URL,
              userListsUrl: metaEnv.SNOWPACK_PUBLIC_USER_LISTS_API_URL,
              itemManagementUrl: metaEnv.SNOWPACK_PUBLIC_ITEM_MANAGEMENT_URL,
              webArchivesServiceUrl: metaEnv.SNOWPACK_PUBLIC_WEB_ARCHIVES_SERVICE_URL,
              sentry: {
                dsn: metaEnv.SNOWPACK_PUBLIC_SENTRY_DSN,
                tracesSampleRate,
                sampleRate: sentryErrorSampleRate
              },
              zendeskHelpWidgetKey: metaEnv.SNOWPACK_PUBLIC_ZENDESK_HELP_WIDGET_KEY,
              isPrerendering,
              isTesting,
              suppressFacets,
              recaptchaSiteKey: metaEnv.SNOWPACK_PUBLIC_RECAPTCHA_SITE_KEY,
              donationForm: {
                braintreeAuthToken: metaEnv.SNOWPACK_PUBLIC_BRAINTREE_AUTH_TOKEN,
                venmoProfileId: metaEnv.SNOWPACK_PUBLIC_VENMO_PROFILE_ID,
                googlepayMerchantId: metaEnv.SNOWPACK_PUBLIC_GOOGLEPAY_MERCHANT_ID
              },
              featureFeedback: {
                serviceUrl: metaEnv.SNOWPACK_PUBLIC_FEATURE_FEEDBACK_SERVICE_URL
              },
              baseImageUrl: metaEnv.SNOWPACK_PUBLIC_ARCHIVE_IMAGE_URL,
              currentBaseUrl: window.location.origin
            };
          }
        });
      }
    }, {
      kind: "field",
      decorators: [state()],
      key: "footerPosition",
      value() {
        return AppFooterMode.Off;
      }
    }, {
      kind: "field",
      decorators: [state()],
      key: "hasLoadedStrings",
      value() {
        return false;
      }
    }, {
      kind: "field",
      decorators: [state()],
      key: "showDebugBorders",
      value() {
        return false;
      }
    }, {
      kind: "field",
      decorators: [state()],
      key: "showDevMenu",
      value() {
        return false;
      }
    }, {
      kind: "field",
      decorators: [state()],
      key: "activityIndicatorVisible",
      value() {
        return true;
      }
    }, {
      kind: "field",
      decorators: [state()],
      key: "sharedResizeObserver",
      value: void 0
    }, {
      kind: "field",
      decorators: [state()],
      key: "siteHasLimitedFunctionality",
      value() {
        return false;
      }
    }, {
      kind: "field",
      decorators: [state()],
      key: "waybackPagesArchived",
      value() {
        return "";
      }
    }, {
      kind: "field",
      decorators: [state()],
      key: "screenName",
      value() {
        return "";
      }
    }, {
      kind: "field",
      decorators: [state()],
      key: "username",
      value() {
        return "";
      }
    }, {
      kind: "field",
      decorators: [state()],
      key: "userHasItemsPriv",
      value() {
        return false;
      }
    }, {
      kind: "field",
      decorators: [state()],
      key: "userHasFlagsPriv",
      value() {
        return false;
      }
    }, {
      kind: "field",
      decorators: [state()],
      key: "zendeskWidgetVisible",
      value() {
        return false;
      }
    }, {
      kind: "field",
      decorators: [state()],
      key: "zendeskWidgetKey",
      value() {
        return "";
      }
    }, {
      kind: "field",
      decorators: [state()],
      key: "donationBannerVisible",
      value: void 0
    }, {
      kind: "field",
      decorators: [state()],
      key: "psaBannerService",
      value: void 0
    }, {
      kind: "field",
      decorators: [state()],
      key: "userService",
      value: void 0
    }, {
      kind: "field",
      decorators: [state()],
      key: "localCache",
      value: void 0
    }, {
      kind: "field",
      decorators: [state()],
      key: "keyValueStoreManager",
      value: void 0
    }, {
      kind: "field",
      decorators: [state()],
      key: "itemIdentifier",
      value: void 0
    }, {
      kind: "field",
      decorators: [query("router-slot")],
      key: "routerSlot",
      value: void 0
    }, {
      kind: "field",
      decorators: [query("ia-topnav")],
      key: "topNav",
      value: void 0
    }, {
      kind: "field",
      decorators: [query("modal-manager")],
      key: "modalManager",
      value: void 0
    }, {
      kind: "field",
      decorators: [query("#maincontent")],
      key: "mainContent",
      value: void 0
    }, {
      kind: "field",
      key: "donationBannerHandler",
      value: void 0
    }, {
      kind: "field",
      key: "donationBannerService",
      value: void 0
    }, {
      kind: "method",
      key: "firstUpdated",
      value: function firstUpdated() {
        this.startup();
      }
    }, {
      kind: "method",
      key: "updated",
      value: function updated(changedProperties) {
        var _a;
        if (changedProperties.has("appServices") && this.appServices) {
          this.setupAppServicesDependents();
        }
        if (changedProperties.has("hasLoadedStrings") && this.hasLoadedStrings) {
          this.setupView();
        }
        if (changedProperties.has("donationBannerVisible") && this.donationBannerVisible) {
          (_a = this.donationBannerHandler) == null ? void 0 : _a.pageNavigationOccurred(!!this.donationBannerVisible);
        }
      }
    }, {
      kind: "method",
      key: "startup",
      value: async function startup() {
        this.checkForLimitedFunctionality();
        await Promise.all([this.setupDonationBannerService(), this.setupDonationBannerHandler(), this.setupPsaBanner(), this.setupKeyValueStoreManager(), this.setupZendeskWidget(), this.startupSentry()]);
      }
    }, {
      kind: "method",
      key: "setupIntersectionObserverPolyfill",
      value: async function setupIntersectionObserverPolyfill() {
        if ("IntersectionObserver" in window)
          return;
        const module = await importRetry(() => import(
          /* webpackChunkName: 'intersectionObserverPolyfill' */
          "../__snowpack__/pkg/intersection-observer-polyfill.js"
        ));
        window.IntersectionObserver = module.default;
        await this.trackEvent({
          event: {
            category: "offshootPolyfills",
            action: "loadIntersectionObserver",
            label: navigator.userAgent
          },
          sampling: true
        });
      }
    }, {
      kind: "method",
      key: "setupPsaBanner",
      value: async function setupPsaBanner() {
        const services = await this.appServices.get();
        await Promise.all([this.psaBannerService = await services.psaBannerService.get(), this.userService = await services.userService.get(), this.localCache = await services.localCache.get()]);
      }
    }, {
      kind: "method",
      key: "setupKeyValueStoreManager",
      value: async function setupKeyValueStoreManager() {
        const services = await this.appServices.get();
        this.keyValueStoreManager = await services.keyValueStoreManager.get();
      }
    }, {
      kind: "method",
      key: "setupZendeskWidget",
      value: async function setupZendeskWidget() {
        const appServices = await this.appServices.get();
        const appConfig = await appServices.appConfig.get();
        const configService = await appServices.configService.get();
        const value = await configService.getConfig("feature.zendesk_enabled");
        this.zendeskWidgetKey = appConfig.zendeskHelpWidgetKey;
        this.zendeskWidgetVisible = value !== "";
      }
    }, {
      kind: "method",
      key: "setupAppServicesDependents",
      value: async function setupAppServicesDependents() {
        await Promise.all([this.setupDevMenu(), this.setupLocalizationManager(), this.startupThemeManager(), this.trackIaxParameter(), this.setupPageViewAnalytics(), this.setupPageMetadataService(), this.setupLightDOMTunnelReset(), this.setupResizeObserver()]);
      }
    }, {
      kind: "method",
      key: "startupSentry",
      value: async function startupSentry() {
        const appConfig = await this.appConfig.get();
        if (appConfig.isTesting || appConfig.isPrerendering)
          return;
        const {
          SentryConfigurer
        } = await importRetry(() => import(
          /* webpackChunkName: 'sentry' */
          "./core/sentry.js"
        ));
        const sentryConfigurer = new SentryConfigurer(appConfig);
        sentryConfigurer.configure();
      }
    }, {
      kind: "method",
      key: "checkForLimitedFunctionality",
      value: async function checkForLimitedFunctionality() {
        const urlParams = new URLSearchParams(window.location.search);
        const limitedFunctionalityParam = urlParams.get("limited");
        this.siteHasLimitedFunctionality = limitedFunctionalityParam === "1";
      }
    }, {
      kind: "field",
      key: "iaxParameterTracked",
      value() {
        return false;
      }
    }, {
      kind: "method",
      key: "trackIaxParameter",
      value: async function trackIaxParameter() {
        if (this.iaxParameterTracked)
          return;
        const appServices = await this.appServices.get();
        const analyticsHelper = await appServices.analyticsHandler.get();
        analyticsHelper.trackIaxParameter(window.location.href);
        this.iaxParameterTracked = true;
      }
    }, {
      kind: "method",
      key: "setupDevMenu",
      value: async function setupDevMenu() {
        const appServices = await this.appServices.get();
        const appConfig = await appServices.appConfig.get();
        const user = await this.getLoggedInUser();
        const isDevEnvironment = appConfig.environment === "dev";
        const userHasPrivs = user && user.privs.includes("offshoot-dev") || false;
        this.showDevMenu = isDevEnvironment || userHasPrivs;
      }
    }, {
      kind: "method",
      key: "setupPageViewAnalytics",
      value: async function setupPageViewAnalytics() {
        window.addEventListener("navigationend", async () => {
          const appServices = await this.appServices.get();
          const analyticsHelper = await appServices.analyticsHandler.get();
          analyticsHelper.trackPageView();
        });
      }
    }, {
      kind: "method",
      key: "setupPageMetadataService",
      value: async function setupPageMetadataService() {
        window.addEventListener("navigationstart", async () => {
          const appServices = await this.appServices.get();
          const pageMetadataService = await appServices.pageMetadataService.get();
          pageMetadataService.clearMetaTags();
          pageMetadataService.clearLinkTags();
        });
      }
    }, {
      kind: "method",
      key: "setupLightDOMTunnelReset",
      value: async function setupLightDOMTunnelReset() {
        window.addEventListener("navigationstart", async () => {
          const appServices = await this.appServices.get();
          const lightDOMTunnel = await appServices.lightDOMTunnel.get();
          lightDOMTunnel.removeAll();
        });
      }
    }, {
      kind: "method",
      key: "setupResizeObserver",
      value: async function setupResizeObserver() {
        const appServices = await this.appServices.get();
        const resizeObserver = await appServices.resizeObserver.get();
        this.sharedResizeObserver = resizeObserver;
      }
    }, {
      kind: "method",
      key: "setupPageNavigationActivityIndicator",
      value: async function setupPageNavigationActivityIndicator() {
        window.addEventListener("navigationstart", async () => {
          this.showActivityIndicator(true);
        });
        window.addEventListener("navigationend", async () => {
          this.showActivityIndicator(false);
        });
      }
    }, {
      kind: "method",
      key: "setupDonationBannerService",
      value: async function setupDonationBannerService() {
        var _a, _b, _c, _d;
        const appServices = await this.appServices.get();
        const fetchHandler = await appServices.fetchHandler.get();
        this.donationBannerService = new DonateBannerService({
          fetchHandler
        });
        const urlParams = new URLSearchParams(window.location.search);
        const ymdParam = (_a = urlParams.get("ymd")) != null ? _a : void 0;
        const variantParam = (_b = urlParams.get("variant")) != null ? _b : void 0;
        const platformParam = (_c = urlParams.get("platform")) != null ? _c : void 0;
        const recacheParam = (_d = urlParams.get("recacheDonationBanner")) != null ? _d : void 0;
        const bannerConfig = await this.donationBannerService.getBannerConfig({
          ymd: ymdParam,
          variant: variantParam,
          platform: platformParam,
          recacheDonationBanner: recacheParam
        });
        if (!bannerConfig)
          return;
        this.donationBannerVisible = bannerConfig.shouldDisplayBanner;
      }
    }, {
      kind: "method",
      key: "setupDonationBannerHandler",
      value: async function setupDonationBannerHandler() {
        const appConfig = await this.appConfig.get();
        this.donationBannerHandler = new DonationBannerHandler({
          baseUrl: appConfig.archiveApiUrl
        });
        window.addEventListener("navigationend", async () => {
          var _a;
          await ((_a = this.donationBannerHandler) == null ? void 0 : _a.pageNavigationOccurred(!!this.donationBannerVisible));
        });
      }
    }, {
      kind: "method",
      key: "setupView",
      value: async function setupView() {
        await this.setupIntersectionObserverPolyfill();
        await Promise.all([this.setupPageNavigationActivityIndicator(), this.setupRouter(), this.setupTopnav()]);
      }
    }, {
      kind: "method",
      key: "setFooterPosition",
      value: async function setFooterPosition(footerPosition) {
        this.footerPosition = footerPosition;
      }
    }, {
      kind: "method",
      key: "showTopNavSearch",
      value: function showTopNavSearch(showSearch) {
        this.topNav.hideSearch = !showSearch;
      }
    }, {
      kind: "method",
      key: "showTopNav2ndLogo",
      value: function showTopNav2ndLogo(allow2ndLogo) {
        const slotMode = allow2ndLogo ? "allow" : "";
        this.topNav.secondIdentitySlotMode = slotMode;
      }
    }, {
      kind: "method",
      key: "showZendeskWidget",
      value: function showZendeskWidget(show) {
        this.zendeskWidgetVisible = show;
      }
    }, {
      kind: "method",
      key: "setItemIdentifier",
      value: function setItemIdentifier(identifier) {
        this.itemIdentifier = identifier;
      }
    }, {
      kind: "method",
      key: "addAllowedCustomElementsToPage",
      value: function addAllowedCustomElementsToPage() {
        this.convertDayInHistoryLinks();
        this.convertDisabilityEligibilityForms();
      }
    }, {
      kind: "method",
      key: "convertDayInHistoryLinks",
      value: function convertDayInHistoryLinks() {
        var _a, _b;
        const collectionPage = (_a = this.routerSlot) == null ? void 0 : _a.querySelector("collection-page");
        const dayInHistoryLinks = (_b = collectionPage == null ? void 0 : collectionPage.shadowRoot) == null ? void 0 : _b.querySelectorAll('a[onclick*="tapeoftheday("]');
        dayInHistoryLinks == null ? void 0 : dayInHistoryLinks.forEach((link) => {
          const text = link.textContent;
          const replacementElmt = document.createElement("day-in-history");
          replacementElmt.textContent = text;
          link.replaceWith(replacementElmt);
        });
      }
    }, {
      kind: "method",
      key: "convertDisabilityEligibilityForms",
      value: function convertDisabilityEligibilityForms() {
        var _a, _b, _c;
        const collectionPage = (_a = this.routerSlot) == null ? void 0 : _a.querySelector("collection-page");
        const aboutSection = (_b = collectionPage == null ? void 0 : collectionPage.shadowRoot) == null ? void 0 : _b.querySelector("collection-about");
        const formSections = (_c = aboutSection == null ? void 0 : aboutSection.shadowRoot) == null ? void 0 : _c.querySelectorAll(".disability-eligibility-provider-form");
        formSections == null ? void 0 : formSections.forEach((section) => {
          var _a2, _b2, _c2, _d, _e, _f;
          const parent = section.parentElement;
          const replacementElmt = document.createElement("disability-eligibility-form");
          const form = section.querySelector("form");
          replacementElmt.setAttribute("type", (_a2 = form == null ? void 0 : form.dataset.disabilityEligibilityType) != null ? _a2 : "");
          replacementElmt.toggleAttribute("ispassword", !!section.querySelector('input[type="password"]'));
          replacementElmt.toggleAttribute("open", (_b2 = parent == null ? void 0 : parent.open) != null ? _b2 : false);
          const title = parent == null ? void 0 : parent.querySelector("h2");
          title == null ? void 0 : title.setAttribute("slot", "title");
          replacementElmt.append(title != null ? title : "");
          const label = document.createElement("span");
          label.textContent = (_d = (_c2 = section.querySelector("label")) == null ? void 0 : _c2.textContent) != null ? _d : "";
          label.setAttribute("slot", "input-label");
          replacementElmt.append(label);
          (_e = section.querySelectorAll("p")) == null ? void 0 : _e.forEach((para) => {
            replacementElmt.append(para);
          });
          (_f = section.parentElement) == null ? void 0 : _f.replaceWith(replacementElmt);
        });
      }
    }, {
      kind: "method",
      key: "getWindowScrollbarWidth",
      value: function getWindowScrollbarWidth() {
        return window.innerWidth - document.documentElement.clientWidth;
      }
    }, {
      kind: "field",
      key: "activityIndicatorRequests",
      value() {
        return 0;
      }
    }, {
      kind: "method",
      key: "showActivityIndicator",
      value: function showActivityIndicator(showIndicator) {
        this.activityIndicatorRequests += showIndicator ? 1 : -1;
        this.activityIndicatorRequests = Math.max(0, this.activityIndicatorRequests);
        const shouldHideIndicator = !showIndicator && this.activityIndicatorRequests === 0;
        this.activityIndicatorVisible = shouldHideIndicator ? false : true;
      }
    }, {
      kind: "method",
      key: "isListsCalloutEnabled",
      value: async function isListsCalloutEnabled() {
        var _a, _b;
        try {
          const appServices = await this.appServices.get();
          const [keyValueStoreManager, abtestManager] = await Promise.all([appServices.keyValueStoreManager.get(), appServices.abtestManager.get()]);
          const userListsStore = keyValueStoreManager == null ? void 0 : keyValueStoreManager.getKeyValueStore({
            namespace: "UserLists",
            storageType: "localStorage"
          });
          const [calloutsVariant, alreadyDismissed] = await Promise.all([abtestManager.variantFor("UserListsLaunchCallouts"), userListsStore == null ? void 0 : userListsStore.get("tabCalloutDismissed")]);
          return calloutsVariant === "On" && !alreadyDismissed;
        } catch (err) {
          (_b = (_a = window.Sentry) == null ? void 0 : _a.captureMessage) == null ? void 0 : _b.call(_a, "Unable to load lists callout state: " + err, "error");
          return false;
        }
      }
    }, {
      kind: "method",
      key: "setupTopnav",
      value: async function setupTopnav() {
        const appConfig = await this.appConfig.get();
        this.topNav.baseHost = appConfig.archiveNavUrl;
        this.waybackPagesArchived = await this.getWaybackPagesArchived();
        const user = await this.getLoggedInUser();
        if (!user)
          return;
        this.screenName = user.screenname;
        this.username = user.itemname.replace(/^@/, "");
        const hasSlashPriv = user.privs.includes("/");
        this.userHasItemsPriv = hasSlashPriv || user.privs.includes("/items");
        this.userHasFlagsPriv = hasSlashPriv || user.privs.includes("/flags");
        await this.updateTopNavCallouts();
      }
    }, {
      kind: "method",
      key: "updateTopNavCallouts",
      value: async function updateTopNavCallouts() {
        const showListsCallout = await this.isListsCalloutEnabled();
        if (this.topNav.config) {
          this.topNav.config = __assign(__assign({}, this.topNav.config), {
            callouts: showListsCallout ? {
              "My lists": "NEW"
            } : {}
          });
        }
      }
    }, {
      kind: "method",
      key: "getWaybackPagesArchived",
      value: async function getWaybackPagesArchived() {
        var _a;
        const appServices = await this.appServices.get();
        const mediaCountService = await appServices.mediaCountService.get();
        const mediaCounts = await mediaCountService.fetchMediaCounts();
        return formatMediaCount((_a = mediaCounts == null ? void 0 : mediaCounts.web) != null ? _a : 0);
      }
    }, {
      kind: "method",
      key: "getLoggedInUser",
      value: async function getLoggedInUser() {
        const appServices = await this.appServices.get();
        const userService = await appServices.userService.get();
        const result = await userService.getLoggedInUser();
        return result.success;
      }
    }, {
      kind: "method",
      key: "startupThemeManager",
      value: async function startupThemeManager() {
        const appServices = await this.appServices.get();
        const themeManager = await appServices.themeManager.get();
        await themeManager.startup();
      }
    }, {
      kind: "method",
      key: "setupRouter",
      value: async function setupRouter() {
        if (!this.routerSlot)
          return;
        const routeBuilder = new RouteBuilder(this.appServices);
        this.routerSlot.handleAnchorLinks = false;
        this.routerSlot.add(routeBuilder.appRoutes);
      }
    }, {
      kind: "method",
      key: "setupLocalizationManager",
      value: async function setupLocalizationManager() {
        const appServices = await this.appServices.get();
        const localizationManager = await appServices.localizationManager.get();
        this.showActivityIndicator(true);
        await localizationManager.setup();
        this.hasLoadedStrings = true;
        this.showActivityIndicator(false);
      }
    }, {
      kind: "method",
      key: "render",
      value: function render() {
        return html`
      <a
        href="#maincontent"
        id="skip-to-main-content-link"
        @click=${this.skipToMainContent}
        >${msg("Skip to main content")}</a
      >

      <header>
        ${this.psaBannerTemplate}
        ${this.siteHasLimitedFunctionality ? this.limitedSiteFunctionalityBannerTemplate : nothing}
        <ia-topnav
          localLinks="true"
          waybackPagesArchived=${this.waybackPagesArchived}
          @trackClick=${this.trackTopNavEvent}
          @trackSubmit=${this.trackTopNavEvent}
          screenName=${this.screenName}
          username=${this.username}
          itemIdentifier=${this.itemIdentifier}
          ?admin=${this.userHasItemsPriv}
          ?canManageFlags=${this.userHasFlagsPriv}
        >
        </ia-topnav>
        ${this.showDevMenu ? this.devMenuTemplate : nothing}
        ${this.activityIndicatorVisible ? this.activityIndicatorTemplate : nothing}
      </header>

      ${this.hasLoadedStrings ? this.mainTemplate : nothing}
      ${this.modalManagerTemplate}
      ${this.zendeskWidgetVisible ? this.zendeskHelpWidgetTemplate : nothing}
    `;
      }
    }, {
      kind: "get",
      key: "psaBannerTemplate",
      value: function psaBannerTemplate() {
        return lazyLoadTemplate(async () => {
          await importRetry(() => import(
            /* webpackChunkName: 'psaBanners' */
            "./widgets/psa-banners.js"
          ));
        }, () => {
          return html`
          <psa-banners
            .psaBannerService=${this.psaBannerService}
            .userService=${this.userService}
            .keyValueStoreManager=${this.keyValueStoreManager}
          >
          </psa-banners>
        `;
        });
      }
    }, {
      kind: "method",
      key: "skipToMainContent",
      value: function skipToMainContent(e) {
        var _a;
        e.preventDefault();
        (_a = this.mainContent) == null ? void 0 : _a.scrollIntoView({
          behavior: "smooth"
        });
        setTimeout(() => {
          var _a2;
          (_a2 = this.mainContent) == null ? void 0 : _a2.focus();
        }, 250);
      }
    }, {
      kind: "get",
      key: "limitedSiteFunctionalityBannerTemplate",
      value: function limitedSiteFunctionalityBannerTemplate() {
        return lazyLoadTemplate(async () => {
          await importRetry(() => import(
            /* webpackChunkName: 'alertBanner' */
            "./widgets/alert-banner.js"
          ));
        }, () => {
          return html`
          <alert-banner .level=${"warn"}>
            ${msg("Your browser may not be compatible with all the features on this site. Consider upgrading to a modern browser for an improved experience.")}
          </alert-banner>
        `;
        });
      }
    }, {
      kind: "get",
      key: "zendeskHelpWidgetTemplate",
      value: function zendeskHelpWidgetTemplate() {
        return lazyLoadTemplate(async () => {
          await importRetry(() => import(
            /* webpackChunkName: 'zenDeskHelpWidget' */
            "../__snowpack__/pkg/@internetarchive/ia-zendesk-help-widget.js"
          ));
        }, () => {
          return html`
          <ia-zendesk-help-widget
            widgetSrc="https://static.zdassets.com/ekr/snippet.js?key=${this.zendeskWidgetKey}"
          ></ia-zendesk-help-widget>
        `;
        });
      }
    }, {
      kind: "get",
      key: "devMenuTemplate",
      value: function devMenuTemplate() {
        return lazyLoadTemplate(async () => {
          await importRetry(() => import(
            /* webpackChunkName: 'devTools' */
            "./dev-tools.js"
          ));
        }, () => {
          return html`
          <dev-tools
            .appServices=${this.appServices}
            @pageWidthSliderChanged=${this.devToolPageWidthSliderChanged}
            @borderCheckboxChecked=${this.devToolsBorderCheckboxChecked}
          >
          </dev-tools>
        `;
        });
      }
    }, {
      kind: "get",
      key: "activityIndicatorTemplate",
      value: function activityIndicatorTemplate() {
        return html`
      <horizontal-activity-indicator
        .resizeObserver=${this.sharedResizeObserver}
      >
      </horizontal-activity-indicator>
    `;
      }
    }, {
      kind: "get",
      key: "mainTemplate",
      value: function mainTemplate() {
        return html`
      <main id="maincontent" tabindex="-1">
        <div
          class="page-container ${this.showDebugBorders ? "show-debug-borders" : ""}"
        >
          <router-slot></router-slot>
        </div>
      </main>

      <footer class="${this.footerPosition}">${this.footerTemplate}</footer>
    `;
      }
    }, {
      kind: "get",
      key: "modalManagerTemplate",
      value: function modalManagerTemplate() {
        return lazyLoadTemplate(async () => {
          await importRetry(() => import(
            /* webpackChunkName: 'modalManager' */
            "../__snowpack__/pkg/@internetarchive/modal-manager.js"
          ));
        }, () => {
          return html`
          <modal-manager>
            <slot
              name="paypal-upsell-button"
              slot="paypal-upsell-button"
            ></slot>
          </modal-manager>
        `;
        });
      }
    }, {
      kind: "method",
      key: "devToolPageWidthSliderChanged",
      value: function devToolPageWidthSliderChanged(e) {
        var _a, _b;
        const value = e.detail.width;
        (_b = (_a = this.shadowRoot) == null ? void 0 : _a.host) == null ? void 0 : _b.style.setProperty("--app-root-page-max-width", value + "rem");
      }
    }, {
      kind: "method",
      key: "devToolsBorderCheckboxChecked",
      value: function devToolsBorderCheckboxChecked(e) {
        const value = e.detail.showBorders;
        this.showDebugBorders = value;
      }
    }, {
      kind: "method",
      key: "trackTopNavEvent",
      value: async function trackTopNavEvent(e) {
        var _a, _b;
        const [category, action] = (_b = (_a = e.detail) == null ? void 0 : _a.event) == null ? void 0 : _b.split("|");
        if (!category || !action)
          return;
        await this.trackEvent({
          event: {
            category,
            action
          },
          sampling: true
        });
      }
    }, {
      kind: "method",
      key: "trackEvent",
      value: async function trackEvent(options) {
        const appServices = await this.appServices.get();
        const analyticsHandler = await appServices.analyticsHandler.get();
        if (options.sampling) {
          analyticsHandler.sendEvent(options.event);
        } else {
          analyticsHandler.sendEventNoSampling(options.event);
        }
      }
    }, {
      kind: "get",
      key: "footerTemplate",
      value: function footerTemplate() {
        return lazyLoadTemplate(async () => {
          await importRetry(() => import(
            /* webpackChunkName: 'appFooter' */
            "./core/app-footer.js"
          ));
        }, () => {
          return html` <app-footer></app-footer> `;
        });
      }
    }, {
      kind: "get",
      static: true,
      key: "styles",
      value: function styles() {
        return css`
      #skip-to-main-content-link {
        /*http://webaim.org/techniques/css/invisiblecontent/*/
        position: absolute;
        clip: rect(0, 0, 0, 0);
        top: auto;
        width: 1px;
        height: 1px;
        overflow: hidden;
        font-size: 1.4rem;
        color: var(--ia-theme-link-color, #4b64ff);
      }
      #skip-to-main-content-link:focus {
        position: static;
        width: auto;
        height: auto;
        outline: thin dotted #333;
        outline: 3px auto -webkit-focus-ring-color;
        margin: 5px 0 2px 8px;
        padding: 2px 3px;
        display: inline-block;
      }
      #skip-to-main-content-link:visited {
        color: var(--ia-theme-link-color, #4b64ff);
      }

      horizontal-activity-indicator {
        position: absolute;
        height: 3px;
        width: 100%;
      }
      modal-manager[mode='closed'] {
        display: none;
      }
      modal-manager.more-search-facets {
        --modalWidth: 85rem;
        --modalBorder: 2px solid #194880;
        --modalTitleLineHeight: 4rem;
        --modalTitleFontSize: 1.8rem;
        --modalCornerRadius: 0;
        --modalBottomPadding: 0;
        --modalScrollOffset: 0;
        --modalCornerRadius: 0.5rem;
      }
      modal-manager.expanded-date-picker {
        --modalWidth: 58rem;
        --modalBorder: 2px solid var(--primaryButtonBGColor, #194880);
        --modalTitleLineHeight: 4rem;
        --modalTitleFontSize: 1.8rem;
        --modalCornerRadius: 0;
        --modalBottomPadding: 0;
        --modalBottomMargin: 0;
        --modalScrollOffset: 0;
        --modalCornerRadius: 0.5rem;
      }
      modal-manager.remove-items {
        --modalWidth: 58rem;
        --modalBorder: 2px solid var(--primaryButtonBGColor, #194880);
        --modalTitleLineHeight: 4rem;
        --modalTitleFontSize: 1.8rem;
        --modalCornerRadius: 0;
        --modalBottomPadding: 0;
        --modalBottomMargin: 0;
        --modalScrollOffset: 0;
        --modalCornerRadius: 0.5rem;
      }
      modal-manager.create-user-list {
        --modalTitleLineHeight: 4.5rem;
        --modalHeaderBottomPadding: 0;
        --modalWidth: 40rem;
      }
      modal-manager.delete-user-list {
        --modalTitleLineHeight: 4.5rem;
        --modalHeaderBottomPadding: 0;
        --modalWidth: 40rem;
      }

      app-footer {
        display: block;
      }
      .page-container {
        margin: auto;
      }
      .page-container.show-debug-borders {
        outline: 1px solid purple;
      }
      footer {
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
      }
      footer.fixed {
        position: fixed;
      }
      footer.off {
        display: none;
      }
    `;
      }
    }]
  };
}, LitElement);
