function _decorate(e, r, t, i) {
  var o = _getDecoratorsApi();
  if (i)
    for (var n = 0; n < i.length; n++)
      o = i[n](o);
  var s = r(function(e2) {
    o.initializeInstanceElements(e2, a.elements);
  }, t), a = o.decorateClass(_coalesceClassElements(s.d.map(_createElementDescriptor)), e);
  return o.initializeClassElements(s.F, a.elements), o.runClassFinishers(s.F, a.finishers);
}
function _getDecoratorsApi() {
  _getDecoratorsApi = function() {
    return e;
  };
  var e = {elementsDefinitionOrder: [["method"], ["field"]], initializeInstanceElements: function(e2, r) {
    ["method", "field"].forEach(function(t) {
      r.forEach(function(r2) {
        r2.kind === t && r2.placement === "own" && this.defineClassElement(e2, r2);
      }, this);
    }, this);
  }, initializeClassElements: function(e2, r) {
    var t = e2.prototype;
    ["method", "field"].forEach(function(i) {
      r.forEach(function(r2) {
        var o = r2.placement;
        if (r2.kind === i && (o === "static" || o === "prototype")) {
          var n = o === "static" ? e2 : t;
          this.defineClassElement(n, r2);
        }
      }, this);
    }, this);
  }, defineClassElement: function(e2, r) {
    var t = r.descriptor;
    if (r.kind === "field") {
      var i = r.initializer;
      t = {enumerable: t.enumerable, writable: t.writable, configurable: t.configurable, value: i === void 0 ? void 0 : i.call(e2)};
    }
    Object.defineProperty(e2, r.key, t);
  }, decorateClass: function(e2, r) {
    var t = [], i = [], o = {static: [], prototype: [], own: []};
    if (e2.forEach(function(e3) {
      this.addElementPlacement(e3, o);
    }, this), e2.forEach(function(e3) {
      if (!_hasDecorators(e3))
        return t.push(e3);
      var r2 = this.decorateElement(e3, o);
      t.push(r2.element), t.push.apply(t, r2.extras), i.push.apply(i, r2.finishers);
    }, this), !r)
      return {elements: t, finishers: i};
    var n = this.decorateConstructor(t, r);
    return i.push.apply(i, n.finishers), n.finishers = i, n;
  }, addElementPlacement: function(e2, r, t) {
    var i = r[e2.placement];
    if (!t && i.indexOf(e2.key) !== -1)
      throw new TypeError("Duplicated element (" + e2.key + ")");
    i.push(e2.key);
  }, decorateElement: function(e2, r) {
    for (var t = [], i = [], o = e2.decorators, n = o.length - 1; n >= 0; n--) {
      var s = r[e2.placement];
      s.splice(s.indexOf(e2.key), 1);
      var a = this.fromElementDescriptor(e2), l = this.toElementFinisherExtras((0, o[n])(a) || a);
      e2 = l.element, this.addElementPlacement(e2, r), l.finisher && i.push(l.finisher);
      var c = l.extras;
      if (c) {
        for (var p = 0; p < c.length; p++)
          this.addElementPlacement(c[p], r);
        t.push.apply(t, c);
      }
    }
    return {element: e2, finishers: i, extras: t};
  }, decorateConstructor: function(e2, r) {
    for (var t = [], i = r.length - 1; i >= 0; i--) {
      var o = this.fromClassDescriptor(e2), n = this.toClassDescriptor((0, r[i])(o) || o);
      if (n.finisher !== void 0 && t.push(n.finisher), n.elements !== void 0) {
        e2 = n.elements;
        for (var s = 0; s < e2.length - 1; s++)
          for (var a = s + 1; a < e2.length; a++)
            if (e2[s].key === e2[a].key && e2[s].placement === e2[a].placement)
              throw new TypeError("Duplicated element (" + e2[s].key + ")");
      }
    }
    return {elements: e2, finishers: t};
  }, fromElementDescriptor: function(e2) {
    var r = {kind: e2.kind, key: e2.key, placement: e2.placement, descriptor: e2.descriptor};
    return Object.defineProperty(r, Symbol.toStringTag, {value: "Descriptor", configurable: true}), e2.kind === "field" && (r.initializer = e2.initializer), r;
  }, toElementDescriptors: function(e2) {
    if (e2 !== void 0)
      return _toArray(e2).map(function(e3) {
        var r = this.toElementDescriptor(e3);
        return this.disallowProperty(e3, "finisher", "An element descriptor"), this.disallowProperty(e3, "extras", "An element descriptor"), r;
      }, this);
  }, toElementDescriptor: function(e2) {
    var r = e2.kind + "";
    if (r !== "method" && r !== "field")
      throw new TypeError(`An element descriptor's .kind property must be either "method" or "field", but a decorator created an element descriptor with .kind "` + r + '"');
    var t = _toPropertyKey(e2.key), i = e2.placement + "";
    if (i !== "static" && i !== "prototype" && i !== "own")
      throw new TypeError(`An element descriptor's .placement property must be one of "static", "prototype" or "own", but a decorator created an element descriptor with .placement "` + i + '"');
    var o = e2.descriptor;
    this.disallowProperty(e2, "elements", "An element descriptor");
    var n = {kind: r, key: t, placement: i, descriptor: Object.assign({}, o)};
    return r !== "field" ? this.disallowProperty(e2, "initializer", "A method descriptor") : (this.disallowProperty(o, "get", "The property descriptor of a field descriptor"), this.disallowProperty(o, "set", "The property descriptor of a field descriptor"), this.disallowProperty(o, "value", "The property descriptor of a field descriptor"), n.initializer = e2.initializer), n;
  }, toElementFinisherExtras: function(e2) {
    return {element: this.toElementDescriptor(e2), finisher: _optionalCallableProperty(e2, "finisher"), extras: this.toElementDescriptors(e2.extras)};
  }, fromClassDescriptor: function(e2) {
    var r = {kind: "class", elements: e2.map(this.fromElementDescriptor, this)};
    return Object.defineProperty(r, Symbol.toStringTag, {value: "Descriptor", configurable: true}), r;
  }, toClassDescriptor: function(e2) {
    var r = e2.kind + "";
    if (r !== "class")
      throw new TypeError(`A class descriptor's .kind property must be "class", but a decorator created a class descriptor with .kind "` + r + '"');
    this.disallowProperty(e2, "key", "A class descriptor"), this.disallowProperty(e2, "placement", "A class descriptor"), this.disallowProperty(e2, "descriptor", "A class descriptor"), this.disallowProperty(e2, "initializer", "A class descriptor"), this.disallowProperty(e2, "extras", "A class descriptor");
    var t = _optionalCallableProperty(e2, "finisher");
    return {elements: this.toElementDescriptors(e2.elements), finisher: t};
  }, runClassFinishers: function(e2, r) {
    for (var t = 0; t < r.length; t++) {
      var i = (0, r[t])(e2);
      if (i !== void 0) {
        if (typeof i != "function")
          throw new TypeError("Finishers must return a constructor.");
        e2 = i;
      }
    }
    return e2;
  }, disallowProperty: function(e2, r, t) {
    if (e2[r] !== void 0)
      throw new TypeError(t + " can't have a ." + r + " property.");
  }};
  return e;
}
function _createElementDescriptor(e) {
  var r, t = _toPropertyKey(e.key);
  e.kind === "method" ? r = {value: e.value, writable: true, configurable: true, enumerable: false} : e.kind === "get" ? r = {get: e.value, configurable: true, enumerable: false} : e.kind === "set" ? r = {set: e.value, configurable: true, enumerable: false} : e.kind === "field" && (r = {configurable: true, writable: true, enumerable: true});
  var i = {kind: e.kind === "field" ? "field" : "method", key: t, placement: e.static ? "static" : e.kind === "field" ? "own" : "prototype", descriptor: r};
  return e.decorators && (i.decorators = e.decorators), e.kind === "field" && (i.initializer = e.value), i;
}
function _coalesceGetterSetter(e, r) {
  e.descriptor.get !== void 0 ? r.descriptor.get = e.descriptor.get : r.descriptor.set = e.descriptor.set;
}
function _coalesceClassElements(e) {
  for (var r = [], isSameElement = function(e2) {
    return e2.kind === "method" && e2.key === o.key && e2.placement === o.placement;
  }, t = 0; t < e.length; t++) {
    var i, o = e[t];
    if (o.kind === "method" && (i = r.find(isSameElement))) {
      if (_isDataDescriptor(o.descriptor) || _isDataDescriptor(i.descriptor)) {
        if (_hasDecorators(o) || _hasDecorators(i))
          throw new ReferenceError("Duplicated methods (" + o.key + ") can't be decorated.");
        i.descriptor = o.descriptor;
      } else {
        if (_hasDecorators(o)) {
          if (_hasDecorators(i))
            throw new ReferenceError("Decorators can't be placed on different accessors with for the same property (" + o.key + ").");
          i.decorators = o.decorators;
        }
        _coalesceGetterSetter(o, i);
      }
    } else
      r.push(o);
  }
  return r;
}
function _hasDecorators(e) {
  return e.decorators && e.decorators.length;
}
function _isDataDescriptor(e) {
  return e !== void 0 && !(e.value === void 0 && e.writable === void 0);
}
function _optionalCallableProperty(e, r) {
  var t = e[r];
  if (t !== void 0 && typeof t != "function")
    throw new TypeError("Expected '" + r + "' to be a function");
  return t;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return typeof i == "symbol" ? i : i + "";
}
function _toPrimitive(t, r) {
  if (typeof t != "object" || !t)
    return t;
  var e = t[Symbol.toPrimitive];
  if (e !== void 0) {
    var i = e.call(t, r || "default");
    if (typeof i != "object")
      return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (r === "string" ? String : Number)(t);
}
function _toArray(r) {
  return _arrayWithHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableRest();
}
function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if (typeof r == "string")
      return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return t === "Object" && r.constructor && (t = r.constructor.name), t === "Map" || t === "Set" ? Array.from(r) : t === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _arrayLikeToArray(r, a) {
  (a == null || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++)
    n[e] = r[e];
  return n;
}
function _iterableToArray(r) {
  if (typeof Symbol != "undefined" && r[Symbol.iterator] != null || r["@@iterator"] != null)
    return Array.from(r);
}
function _arrayWithHoles(r) {
  if (Array.isArray(r))
    return r;
}
import {LitElement, html, css} from "../../../__snowpack__/pkg/lit.js";
import {property, query, customElement} from "../../../__snowpack__/pkg/lit/decorators.js";
export let HorizontalActivityIndicator = _decorate([customElement("horizontal-activity-indicator")], function(_initialize, _LitElement) {
  class HorizontalActivityIndicator2 extends _LitElement {
    constructor(...args) {
      super(...args);
      _initialize(this);
    }
  }
  return {
    F: HorizontalActivityIndicator2,
    d: [{
      kind: "field",
      decorators: [property({
        type: Object
      })],
      key: "resizeObserver",
      value: void 0
    }, {
      kind: "field",
      decorators: [property({
        type: Boolean
      })],
      key: "adjustSpeedForWidth",
      value() {
        return true;
      }
    }, {
      kind: "field",
      decorators: [query(".track")],
      key: "track",
      value: void 0
    }, {
      kind: "method",
      key: "render",
      value: function render() {
        return html`
      <div class="track">
        <div class="bar"></div>
      </div>
    `;
      }
    }, {
      kind: "method",
      key: "updated",
      value: function updated(changed) {
        const oldObserver = changed.get("resizeObserver");
        if (oldObserver) {
          oldObserver.removeObserver({
            handler: this,
            target: this.track
          });
        }
        if (changed.has("adjustSpeedForWidth") || changed.has("resizeObserver")) {
          if (this.adjustSpeedForWidth) {
            this.setupResizeObserver();
          } else {
            this.disconnectResizeObserver();
          }
        }
      }
    }, {
      kind: "method",
      key: "handleResize",
      value: function handleResize(entry) {
        const width = entry.contentRect.width;
        switch (entry.target) {
          case this.track:
            this.updateLoaderSpeed(width);
          default:
            break;
        }
      }
    }, {
      kind: "method",
      key: "disconnectedCallback",
      value: function disconnectedCallback() {
        this.disconnectResizeObserver();
      }
    }, {
      kind: "method",
      key: "updateLoaderSpeed",
      value: function updateLoaderSpeed(width) {
        var _a, _b;
        const seconds = Math.max(width / 200, 3);
        (_b = (_a = this.shadowRoot) == null ? void 0 : _a.host) == null ? void 0 : _b.style.setProperty("--horizontalActivityIndicatorDuration", seconds + "s");
      }
    }, {
      kind: "method",
      key: "disconnectResizeObserver",
      value: function disconnectResizeObserver() {
        var _a;
        (_a = this.resizeObserver) == null ? void 0 : _a.removeObserver({
          handler: this,
          target: this.track
        });
      }
    }, {
      kind: "method",
      key: "setupResizeObserver",
      value: async function setupResizeObserver() {
        var _a;
        (_a = this.resizeObserver) == null ? void 0 : _a.addObserver({
          handler: this,
          target: this.track
        });
      }
    }, {
      kind: "get",
      static: true,
      key: "styles",
      value: function styles() {
        const indicatorColor = css`var(--horizontalActivityIndicatorColor, dodgerblue)`;
        const indicatorDuration = css`var(--horizontalActivityIndicatorDuration, 7.5s)`;
        return css`
      :host {
        display: block;
      }

      @keyframes loader-animation {
        0% {
          left: -100%;
          width: 100%;
        }
        49% {
          left: 100%;
          width: 10%;
        }
        50% {
          left: 100%;
          width: 100%;
        }
        100% {
          left: -10%;
          width: 10%;
        }
      }

      .track {
        height: 100%;
        width: 100%;
        overflow: hidden;
      }

      .track .bar {
        position: relative;
        height: 100%;
        background-color: ${indicatorColor};
        animation-name: loader-animation;
        animation-duration: ${indicatorDuration};
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
      }
    `;
      }
    }]
  };
}, LitElement);
