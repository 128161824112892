var __assign = Object.assign;
class AnalyticsManager {
  constructor(options) {
    var _a, _b, _c, _d;
    this.ARCHIVE_ANALYTICS_VERSION = 2;
    this.DEFAULT_SERVICE = "ao_2";
    this.NO_SAMPLING_SERVICE = "ao_no_sampling";
    this.DEFAULT_IMAGE_URL = "https://athena.archive.org/0.gif";
    this.defaultService = (_a = options === null || options === void 0 ? void 0 : options.defaultService) !== null && _a !== void 0 ? _a : this.DEFAULT_SERVICE;
    this.imageUrl = (_b = options === null || options === void 0 ? void 0 : options.imageUrl) !== null && _b !== void 0 ? _b : this.DEFAULT_IMAGE_URL;
    this.imageContainer = (_c = options === null || options === void 0 ? void 0 : options.imageContainer) !== null && _c !== void 0 ? _c : document.body;
    this.requireImagePing = (_d = options === null || options === void 0 ? void 0 : options.requireImagePing) !== null && _d !== void 0 ? _d : false;
  }
  sendPing(values) {
    const url = this.generateTrackingUrl(values).toString();
    if (this.requireImagePing) {
      this.sendPingViaImage(url);
      return;
    }
    const send = navigator.sendBeacon && navigator.sendBeacon.bind(navigator);
    try {
      send(url);
    } catch (err) {
      this.sendPingViaImage(url);
    }
  }
  sendEvent(options) {
    const label = options.label && options.label.trim().length > 0 ? options.label : window.location.pathname;
    const eventParams = __assign({
      kind: "event",
      ec: options.category,
      ea: options.action,
      el: label,
      cache_bust: Math.random()
    }, options.eventConfiguration);
    this.sendPing(eventParams);
  }
  sendEventNoSampling(options) {
    const eventConfig = options.eventConfiguration || {};
    eventConfig.service = this.NO_SAMPLING_SERVICE;
    const newOptions = options;
    newOptions.eventConfiguration = eventConfig;
    this.sendEvent(newOptions);
  }
  sendPingViaImage(url) {
    const pingImage = new Image(1, 1);
    pingImage.src = url;
    pingImage.alt = "";
    this.imageContainer.appendChild(pingImage);
  }
  generateTrackingUrl(params) {
    var _a;
    const outputParams = params !== null && params !== void 0 ? params : {};
    outputParams.service = (_a = outputParams.service) !== null && _a !== void 0 ? _a : this.defaultService;
    const url = new URL(this.imageUrl);
    const keys = Object.keys(outputParams);
    keys.forEach((key) => {
      const value = outputParams[key];
      url.searchParams.append(key, value);
    });
    url.searchParams.append("version", `${this.ARCHIVE_ANALYTICS_VERSION}`);
    url.searchParams.append("count", `${keys.length + 2}`);
    return url;
  }
}
class AnalyticsHelpers {
  constructor(analyticsManager) {
    this.analyticsManager = analyticsManager;
  }
  trackIaxParameter(location) {
    const url = new URL(location);
    const iaxParam = url.searchParams.get("iax");
    if (!iaxParam)
      return;
    const eventValues = iaxParam.split("|");
    const actionValue = eventValues.length >= 1 ? eventValues[1] : "";
    const labelValue = eventValues.length >= 2 ? eventValues[2] : "";
    this.analyticsManager.sendEventNoSampling({
      category: eventValues[0],
      action: actionValue,
      label: labelValue
    });
  }
  trackPageView(options) {
    const event = {};
    event.kind = "pageview";
    event.timediff = new Date().getTimezoneOffset() / 60 * -1;
    event.locale = navigator.language;
    event.referrer = document.referrer === "" ? "-" : document.referrer;
    const {domInteractive, defaultFontSize} = this;
    if (domInteractive) {
      event.loadtime = domInteractive;
    }
    if (defaultFontSize) {
      event.iaprop_fontSize = defaultFontSize;
    }
    if ("devicePixelRatio" in window) {
      event.iaprop_devicePixelRatio = window.devicePixelRatio;
    }
    if (options === null || options === void 0 ? void 0 : options.mediaType) {
      event.iaprop_mediaType = options.mediaType;
    }
    if (options === null || options === void 0 ? void 0 : options.mediaLanguage) {
      event.iaprop_mediaLanguage = options.mediaLanguage;
    }
    if (options === null || options === void 0 ? void 0 : options.primaryCollection) {
      event.iaprop_primaryCollection = options.primaryCollection;
    }
    if (options === null || options === void 0 ? void 0 : options.page) {
      event.page = options.page;
    }
    this.analyticsManager.sendPing(event);
  }
  get defaultFontSize() {
    const style = window.getComputedStyle(document.documentElement);
    if (!style)
      return null;
    const fontSizeString = style.fontSize;
    const fontSizeNumber = parseFloat(fontSizeString) * 1.6;
    const unit = fontSizeString.replace(/(\d*\.\d+)|\d+/, "");
    return `${fontSizeNumber}${unit}`;
  }
  get domInteractive() {
    if (!window.performance || !window.performance.getEntriesByType)
      return void 0;
    const performanceEntries = window.performance.getEntriesByType("navigation");
    if (performanceEntries.length === 0)
      return void 0;
    const entry = performanceEntries[0];
    return entry.domInteractive;
  }
}
export {AnalyticsHelpers, AnalyticsManager};
