export async function resolveOwnUserProfile(appServices, tab) {
  const appServicesInstance = await appServices.get();
  const userService = await appServicesInstance.userService.get();
  const loggedInUser = (await userService.getLoggedInUser()).success;
  if (loggedInUser) {
    const userItem = loggedInUser.itemname;
    const tabPath = tab ? `/${tab}` : "";
    const queryStr = window.location.search;
    const queryFragment = window.location.hash;
    history.replaceState(history.state, "", `/details/${userItem}${tabPath}${queryStr}${queryFragment}`);
  } else {
    window.location.pathname = "/login";
  }
}
