const CATCH_ALL_WILDCARD = "**";
const TRAVERSE_FLAG = "\\.\\.\\/";
const PARAM_IDENTIFIER = /:([^\\/]+)/g;
const ROUTER_SLOT_TAG_NAME = "router-slot";
const GLOBAL_ROUTER_EVENTS_TARGET = window;
const HISTORY_PATCH_NATIVE_KEY = `native`;
const DEFAULT_PATH_MATCH = "prefix";
function dispatchRouteChangeEvent($elem, detail) {
  $elem.dispatchEvent(new CustomEvent("changestate", {detail}));
}
function dispatchGlobalRouterEvent(name, detail) {
  GLOBAL_ROUTER_EVENTS_TARGET.dispatchEvent(new CustomEvent(name, {detail}));
}
function addListener($elem, type, listener, options) {
  const types = Array.isArray(type) ? type : [type];
  types.forEach((t) => $elem.addEventListener(t, listener, options));
  return () => types.forEach((t) => $elem.removeEventListener(t, listener, options));
}
function removeListeners(listeners) {
  listeners.forEach((unsub) => unsub());
}
const historyPatches = [
  ["pushState", ["pushstate", "changestate"]],
  ["replaceState", ["replacestate", "changestate"]],
  ["forward", ["pushstate", "changestate"]],
  ["go", ["pushstate", "changestate"]],
  ["back", ["popstate"]]
];
function ensureHistoryEvents() {
  for (const [name, events] of historyPatches) {
    for (const event of events) {
      attachCallback(history, name, () => dispatchGlobalRouterEvent(event));
    }
  }
  window.addEventListener("popstate", (e) => {
    if (shouldCancelChangeState()) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    setTimeout(() => dispatchGlobalRouterEvent("changestate"), 0);
  });
}
function attachCallback(obj, name, cb) {
  const func = obj[name];
  saveNativeFunction(obj, name, func);
  obj[name] = (...args) => {
    if (shouldCancelChangeState())
      return;
    func.apply(obj, args);
    cb(args);
  };
}
function saveNativeFunction(obj, name, func) {
  if (obj[HISTORY_PATCH_NATIVE_KEY] == null) {
    obj[HISTORY_PATCH_NATIVE_KEY] = {};
  }
  obj[HISTORY_PATCH_NATIVE_KEY][`${name}`] = func.bind(obj);
}
function shouldCancelChangeState() {
  return !GLOBAL_ROUTER_EVENTS_TARGET.dispatchEvent(new CustomEvent("willchangestate", {cancelable: true}));
}
const $anchor = document.createElement("a");
function path(options = {}) {
  return slashify(window.location.pathname, options);
}
function pathWithoutBasePath(options = {}) {
  return slashify(stripStart(path(), basePath()), options);
}
function basePath(options = {}) {
  return constructPathWithBasePath(".", options);
}
function constructPathWithBasePath(path2, options = {}) {
  $anchor.href = path2;
  return slashify($anchor.pathname, options);
}
function stripStart(path2, part) {
  return path2.replace(new RegExp(`^${part}`), "");
}
function queryString() {
  return window.location.search;
}
function query() {
  return toQuery(queryString().substr(1));
}
function stripSlash(path2) {
  return slashify(path2, {start: false, end: false});
}
function ensureSlash(path2) {
  return slashify(path2, {start: true, end: true});
}
function slashify(path2, {start = true, end = true} = {}) {
  path2 = start && !path2.startsWith("/") ? `/${path2}` : !start && path2.startsWith("/") ? path2.slice(1) : path2;
  return end && !path2.endsWith("/") ? `${path2}/` : !end && path2.endsWith("/") ? path2.slice(0, path2.length - 1) : path2;
}
function toQuery(queryString2) {
  if (queryString2.length === 0) {
    return {};
  }
  const atoms = queryString2.split("&");
  const arrayMap = atoms.map((atom) => atom.split("="));
  return Object.assign({}, ...arrayMap.map((arr) => ({
    [decodeURIComponent(arr[0])]: arr.length > 1 ? decodeURIComponent(arr[1]) : ""
  })));
}
function toQueryString(query2) {
  return Object.entries(query2).map(([key, value]) => `${key}${value != "" ? `=${encodeURIComponent(value)}` : ""}`).join("&");
}
function isPathActive(path$1, fullPath = path()) {
  return new RegExp(`^${stripSlash(path$1)}(/|$)`, "gm").test(stripSlash(fullPath));
}
function matchRoute(route, path2) {
  const paramNames = [];
  const routePath = stripSlash(route.path.replace(PARAM_IDENTIFIER, (substring, ...args) => {
    paramNames.push(args[0]);
    return `([^/]+)`;
  }));
  const regex = route.path === CATCH_ALL_WILDCARD || route.path.length === 0 && route.pathMatch != "full" ? /^/ : (() => {
    switch (route.pathMatch || DEFAULT_PATH_MATCH) {
      case "full":
        return new RegExp(`^/?${routePath}/?$`);
      case "suffix":
        return new RegExp(`^.*?${routePath}/?$`);
      case "fuzzy":
        return new RegExp(`^.*?${routePath}.*?$`);
      case "prefix":
      default:
        return new RegExp(`^[/]?${routePath}(?:/|$)`);
    }
  })();
  const match = path2.match(regex);
  if (match != null) {
    const params = paramNames.reduce((acc, name, i) => {
      acc[name] = match[i + 1];
      return acc;
    }, {});
    const consumed = stripSlash(path2.slice(0, match[0].length));
    const rest = stripSlash(path2.slice(match[0].length, path2.length));
    return {
      route,
      match,
      params,
      fragments: {
        consumed,
        rest
      }
    };
  }
  return null;
}
function matchRoutes(routes, path2) {
  for (const route of routes) {
    const match = matchRoute(route, path2);
    if (match != null) {
      return match;
    }
  }
  return null;
}
async function resolvePageComponent(route, info) {
  let cmp = route.component;
  if (cmp instanceof Function) {
    try {
      cmp = cmp();
    } catch (err) {
      if (!(err instanceof TypeError)) {
        throw err;
      }
    }
  }
  const moduleClassOrPage = await Promise.resolve(cmp);
  let component;
  if (!(moduleClassOrPage instanceof HTMLElement)) {
    component = new (moduleClassOrPage.default ? moduleClassOrPage.default : moduleClassOrPage)();
  } else {
    component = cmp;
  }
  if (route.setup != null) {
    route.setup(component, info);
  }
  return component;
}
function isRedirectRoute(route) {
  return "redirectTo" in route;
}
function isResolverRoute(route) {
  return "resolve" in route;
}
function traverseRouterTree(slot) {
  let routes = [slot];
  while (slot.parent != null) {
    slot = slot.parent;
    routes.push(slot);
  }
  const tree = routes.reduce((acc, slot2) => {
    return {slot: slot2, child: acc};
  }, void 0);
  const depth = routes.length;
  return {tree, depth};
}
function getFragments(tree, depth) {
  let child = tree;
  const fragments = [];
  while (child != null && child.slot.match != null && depth > 0) {
    fragments.push(child.slot.match.fragments.consumed);
    child = child.child;
    depth--;
  }
  return fragments;
}
function constructAbsolutePath(slot, path2 = "") {
  const {tree, depth} = traverseRouterTree(slot);
  if (!path2.startsWith("/")) {
    let traverseDepth = 0;
    if (path2.startsWith("./")) {
      path2 = path2.slice(2);
    }
    const match = path2.match(new RegExp(TRAVERSE_FLAG, "g"));
    if (match != null) {
      traverseDepth = match.length;
      const length = match.reduce((acc, m) => acc + m.length, 0);
      path2 = path2.slice(length);
    }
    const fragments = getFragments(tree, depth - 1 - traverseDepth).filter((fragment) => fragment.length > 0);
    path2 = `${fragments.join("/")}${fragments.length > 0 ? "/" : ""}${path2}`;
  }
  return constructPathWithBasePath(path2, {end: false});
}
function handleRedirect(slot, route) {
  history.replaceState(history.state, "", `${constructAbsolutePath(slot, route.redirectTo)}${route.preserveQuery ? queryString() : ""}`);
}
function shouldNavigate(currentMatch, newMatch) {
  if (currentMatch == null) {
    return true;
  }
  const {route: currentRoute, fragments: currentFragments} = currentMatch;
  const {route: newRoute, fragments: newFragments} = newMatch;
  const isSameRoute = currentRoute == newRoute;
  const isSameFragments = currentFragments.consumed == newFragments.consumed;
  return !isSameFragments || !isSameRoute;
}
function queryParentRouterSlot($elem) {
  return queryParentRoots($elem, ROUTER_SLOT_TAG_NAME);
}
function queryParentRoots($elem, query2, minRoots = 0, roots = 0) {
  const $root = $elem.getRootNode();
  if (roots >= minRoots) {
    const match = $root.querySelector(query2);
    if (match != null && match != $elem) {
      return match;
    }
  }
  const $rootRootNode = $root.getRootNode();
  if ($rootRootNode.host == null) {
    return null;
  }
  return queryParentRoots($rootRootNode.host, query2, minRoots, ++roots);
}
class AnchorHandler {
  constructor(routerSlot) {
    this.routerSlot = routerSlot;
  }
  setup() {
    this.boundEventHandler = this.handleEvent.bind(this);
    window.addEventListener("click", this.boundEventHandler);
  }
  teardown() {
    window.removeEventListener("click", this.boundEventHandler);
  }
  handleEvent(e) {
    var _a, _b;
    if (!((_a = this.routerSlot) === null || _a === void 0 ? void 0 : _a.handleAnchorLinks))
      return;
    const $anchor2 = "composedPath" in e ? e.composedPath().find(($elem) => $elem instanceof HTMLAnchorElement) : e.target;
    if ($anchor2 == null || !($anchor2 instanceof HTMLAnchorElement)) {
      return;
    }
    const hrefIsRelative = $anchor2.href.startsWith(location.origin);
    const differentFrameTargetted = $anchor2.target !== "" && $anchor2.target !== "_self";
    const isDisabled = $anchor2.dataset["routerSlot"] === "disabled";
    const routeMatched = (_b = this.routerSlot) === null || _b === void 0 ? void 0 : _b.getRouteMatch($anchor2.pathname);
    const userIsHoldingMetaKey = e.metaKey;
    if (!hrefIsRelative || differentFrameTargetted || isDisabled || !routeMatched || userIsHoldingMetaKey) {
      return;
    }
    if (e.metaKey) {
      return;
    }
    const path2 = `${$anchor2.pathname}${$anchor2.search}`;
    e.preventDefault();
    history.pushState(null, "", path2);
  }
}
const template$1 = document.createElement("template");
template$1.innerHTML = `<slot></slot>`;
ensureHistoryEvents();
class RouterSlot extends HTMLElement {
  constructor() {
    super();
    this.listeners = [];
    this._routes = [];
    this._handleAnchors = true;
    this._routeMatch = null;
    this.render = this.render.bind(this);
    const shadow = this.attachShadow({mode: "open"});
    shadow.appendChild(template$1.content.cloneNode(true));
  }
  get routes() {
    return this._routes;
  }
  set routes(routes) {
    this.clear();
    this.add(routes);
  }
  get handleAnchorLinks() {
    return this._handleAnchors;
  }
  set handleAnchorLinks(handleAnchors) {
    this._handleAnchors = handleAnchors;
    if (handleAnchors) {
      this.setupAnchorListener();
    } else {
      this.detachAnchorListener();
    }
  }
  get parent() {
    return this._parent;
  }
  set parent(router) {
    this.detachListeners();
    this._parent = router;
    this.attachListeners();
  }
  get isRoot() {
    return this.parent == null;
  }
  get match() {
    return this._routeMatch;
  }
  get route() {
    return this.match != null ? this.match.route : null;
  }
  get fragments() {
    return this.match != null ? this.match.fragments : null;
  }
  get params() {
    return this.match != null ? this.match.params : null;
  }
  connectedCallback() {
    this.parent = this.queryParentRouterSlot();
    this.setupAnchorListener();
  }
  disconnectedCallback() {
    this.detachListeners();
    this.detachAnchorListener();
  }
  queryParentRouterSlot() {
    return queryParentRouterSlot(this);
  }
  constructAbsolutePath(path2) {
    return constructAbsolutePath(this, path2);
  }
  add(routes, navigate = this.isRoot && this.isConnected) {
    this._routes.push(...routes);
    if (navigate) {
      this.render().then();
    }
  }
  clear() {
    this._routes.length = 0;
  }
  getRouteMatch(path2) {
    const match = matchRoutes(this._routes, path2);
    return match;
  }
  async render() {
    if (!this.isConnected) {
      return;
    }
    const pathFragment = this.parent != null && this.parent.fragments != null ? this.parent.fragments.rest : pathWithoutBasePath();
    await this.renderPath(pathFragment);
  }
  setupAnchorListener() {
    var _a;
    if (!this.isRoot)
      return;
    this.detachAnchorListener();
    this.anchorHandler = new AnchorHandler(this);
    (_a = this.anchorHandler) === null || _a === void 0 ? void 0 : _a.setup();
  }
  detachAnchorListener() {
    var _a;
    (_a = this.anchorHandler) === null || _a === void 0 ? void 0 : _a.teardown();
  }
  attachListeners() {
    this.listeners.push(this.parent != null ? addListener(this.parent, "changestate", this.render) : addListener(GLOBAL_ROUTER_EVENTS_TARGET, "changestate", this.render));
  }
  clearChildren() {
    while (this.firstChild != null) {
      this.firstChild.parentNode.removeChild(this.firstChild);
    }
  }
  detachListeners() {
    removeListeners(this.listeners);
  }
  async renderPath(path2) {
    const match = this.getRouteMatch(path2);
    if (match == null) {
      this._routeMatch = null;
      return false;
    }
    const {route} = match;
    const info = {match, slot: this};
    try {
      const navigate = shouldNavigate(this.match, match);
      if (navigate) {
        let navigationInvalidated = false;
        const cancelNavigation = () => navigationInvalidated = true;
        const removeChangeListener = addListener(GLOBAL_ROUTER_EVENTS_TARGET, "changestate", cancelNavigation, {once: true});
        const cleanup = () => {
          removeChangeListener();
        };
        const cancel = () => {
          cleanup();
          dispatchGlobalRouterEvent("navigationcancel", info);
          dispatchGlobalRouterEvent("navigationend", info);
          return false;
        };
        dispatchGlobalRouterEvent("navigationstart", info);
        if (route.guards != null) {
          for (const guard of route.guards) {
            if (!await guard(info)) {
              return cancel();
            }
          }
        }
        if (isRedirectRoute(route)) {
          cleanup();
          handleRedirect(this, route);
          return false;
        } else if (isResolverRoute(route)) {
          if (await route.resolve(info) === false) {
            return cancel();
          }
        } else {
          const page = await resolvePageComponent(route, info);
          if (navigationInvalidated) {
            return cancel();
          }
          this.clearChildren();
          this._routeMatch = match;
          this.appendChild(page);
        }
        cleanup();
      }
      this._routeMatch = match;
      requestAnimationFrame(() => {
        dispatchRouteChangeEvent(this, info);
      });
      if (navigate) {
        dispatchGlobalRouterEvent("navigationsuccess", info);
        dispatchGlobalRouterEvent("navigationend", info);
      }
      return navigate;
    } catch (e) {
      dispatchGlobalRouterEvent("navigationerror", info);
      dispatchGlobalRouterEvent("navigationend", info);
      throw e;
    }
  }
}
window.customElements.define(ROUTER_SLOT_TAG_NAME, RouterSlot);
const template = document.createElement("template");
template.innerHTML = `<slot></slot>`;
class RouterLink extends HTMLElement {
  constructor() {
    super();
    this.listeners = [];
    this._context = null;
    this.navigate = this.navigate.bind(this);
    this.updateActive = this.updateActive.bind(this);
    const shadow = this.attachShadow({mode: "open", delegatesFocus: this.delegateFocus});
    shadow.appendChild(template.content.cloneNode(true));
  }
  static get observedAttributes() {
    return [
      "disabled"
    ];
  }
  set path(value) {
    this.setAttribute("path", value);
  }
  get path() {
    return this.getAttribute("path") || "/";
  }
  get target() {
    return this.getAttribute("target");
  }
  get disabled() {
    return this.hasAttribute("disabled");
  }
  set disabled(value) {
    value ? this.setAttribute("disabled", "") : this.removeAttribute("disabled");
  }
  get active() {
    return this.hasAttribute("active");
  }
  set active(value) {
    value ? this.setAttribute("active", "") : this.removeAttribute("active");
  }
  get delegateFocus() {
    return this.hasAttribute("delegateFocus");
  }
  set delegateFocus(value) {
    value ? this.setAttribute("delegateFocus", "") : this.removeAttribute("delegateFocus");
  }
  get preserveQuery() {
    return this.hasAttribute("preservequery");
  }
  set preserveQuery(value) {
    value ? this.setAttribute("preservequery", "") : this.removeAttribute("preservequery");
  }
  get context() {
    return this._context;
  }
  set context(value) {
    this._context = value;
  }
  get absolutePath() {
    return this.constructAbsolutePath(this.path);
  }
  connectedCallback() {
    this.listeners.push(addListener(this, "click", (e) => this.navigate(this.path, e)), addListener(this, "keydown", (e) => e.code === "Enter" || e.code === "Space" ? this.navigate(this.path, e) : void 0), addListener(GLOBAL_ROUTER_EVENTS_TARGET, "navigationend", this.updateActive), addListener(GLOBAL_ROUTER_EVENTS_TARGET, "changestate", this.updateActive));
    this.context = queryParentRouterSlot(this);
    this.setAttribute("role", "link");
    if (!this.hasAttribute("tabindex")) {
      this.updateTabIndex();
    }
  }
  disconnectedCallback() {
    removeListeners(this.listeners);
  }
  attributeChangedCallback(name, oldValue, newValue) {
    if (name === "disabled") {
      this.updateTabIndex();
    }
  }
  updateTabIndex() {
    this.tabIndex = this.disabled ? -1 : 0;
  }
  constructAbsolutePath(path2) {
    if (this.context != null) {
      return this.context.constructAbsolutePath(path2);
    }
    return slashify(path2, {end: false});
  }
  updateActive() {
    const active = isPathActive(this.absolutePath);
    if (active !== this.active) {
      this.active = active;
    }
  }
  navigate(path2, e) {
    if (e != null && this.disabled) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    const query2 = this.preserveQuery ? queryString() : "";
    const destination = `${this.absolutePath}${query2}`;
    if (this.target) {
      window.open(destination, this.target);
      return;
    }
    if (e instanceof MouseEvent && e.metaKey) {
      window.open(destination, "_blank");
      return;
    }
    history.pushState(null, "", destination);
  }
}
window.customElements.define("router-link", RouterLink);
export {AnchorHandler, CATCH_ALL_WILDCARD, DEFAULT_PATH_MATCH, GLOBAL_ROUTER_EVENTS_TARGET, HISTORY_PATCH_NATIVE_KEY, PARAM_IDENTIFIER, ROUTER_SLOT_TAG_NAME, RouterLink, RouterSlot, TRAVERSE_FLAG, addListener, attachCallback, basePath, constructAbsolutePath, constructPathWithBasePath, dispatchGlobalRouterEvent, dispatchRouteChangeEvent, ensureHistoryEvents, ensureSlash, getFragments, handleRedirect, historyPatches, isPathActive, isRedirectRoute, isResolverRoute, matchRoute, matchRoutes, path, pathWithoutBasePath, query, queryParentRoots, queryParentRouterSlot, queryString, removeListeners, resolvePageComponent, saveNativeFunction, shouldNavigate, slashify, stripSlash, stripStart, toQuery, toQueryString, traverseRouterTree};
