var __assign = Object.assign;
import {e} from "./property-48b65ec2.js";
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
function t(t2) {
  return e(__assign(__assign({}, t2), {state: true}));
}
export {t};
