/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const e = (e2) => (n2) => typeof n2 == "function" ? ((e3, n3) => (customElements.define(e3, n3), n3))(e2, n2) : ((e3, n3) => {
  const {kind: t, elements: s} = n3;
  return {kind: t, elements: s, finisher(n4) {
    customElements.define(e3, n4);
  }};
})(e2, n2);
/**
 * @license
 * Copyright 2021 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
var n;
((n = window.HTMLSlotElement) === null || n === void 0 ? void 0 : n.prototype.assignedElements) != null ? (o, n2) => o.assignedElements(n2) : (o, n2) => o.assignedNodes(n2).filter((o2) => o2.nodeType === Node.ELEMENT_NODE);
export {e};
